<template>
  <div class="white pa-5 position-relative" height="inherit" style="overflow: auto;border-radius: inherit;">
    <block v-if="loading"></block>
    <v-form v-model="valid">
      <v-row class="ml-2 mr-2">

        <!-- Información del receptor Column Start -->
        <!--v-col cols="12" class="my-6">
          <h2 class="pb-6">Información del receptor:</h2>
  
          <v-row cols="12">
            <v-col cols="12" md="4">
              <v-autocomplete outlined dense label="Nombre de Proveedor:*" :items="providers" @change="changeProviderData"
                required :rules="required" attach v-model="form.providerId" item-text="ProviderName"
                item-value="ID_Provider"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.legalnit" label="Número de NIT:*" @input="maskNit" required  :rules="required"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.nrc" required :rules="required" @input="maskNRC"
                label="NRC:* "></v-text-field>
            </v-col>
          </v-row>

          <v-row cols="12">
            <v-col cols="12" md="6">
              <v-autocomplete outlined dense label="Actividad economica:*" :items="activities"
                @change="changueValueactivity" required :rules="required" attach v-model="form.codactividad"
                item-text="Valores" item-value="Codigo"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.phonenumber" required :rules="required"
                label="Número de télefono:*"></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.mobilenumber" required :rules="required"
                label="Celular: * "></v-text-field>
            </v-col>
          </v-row>
          <v-row cols="12">
            <v-col cols="12" md="4">
              <v-autocomplete outlined dense :items="countryList" attach item-text="name" v-model="form.country" required
                :rules="required" label="País*">
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete outlined dense v-if="form.country == 'El Salvador'" :items="departments"
                v-model="form.department" label="Estado/Departamento*" item-text="Valores" item-value="Codigo" attach />
              <v-autocomplete outlined dense v-else v-model="form.department" label="Estado/Departamento*"
                :items="stateList" item-text="name" required :rules="required" attach />
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete outlined dense :items="municipalities" v-if="form.country == 'El Salvador'"
                :readonly="!form.department" v-model="form.municipality" label="Ciudad*" item-text="Valores"
                item-value="Codigo" required :rules="required" attach />
              <div v-else>
                <v-autocomplete outlined dense v-if="cityList.length > 0" v-model="form.municipality" required
                  :rules="required" label="Ciudad*" item-text="name" :readonly="!form.department" :items="cityList"
                  attach />
                <v-text-field outlined dense v-else v-model="form.municipality" label="Ciudad*"
                  placeholder="Digite la ciudad del cliente" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.email" class="fast-ease-in-transitioned-element" required
                :rules="required" label="Correo eléctronico: "></v-text-field>
            </v-col>
            <v-col cols="12" md="8">
              <v-text-field outlined dense :value="form.address" required :rules="required" label="Dirección:* "
                @input="uppercaseInput('address')" />
            </v-col>
          </v-row>
        </v-col-->
        <!-- Información del receptor Column End-->

        <!-- Cuerpo del documento Column Start-->
        <v-col cols="12" class="mt-2">
          <h2 class="pb-6 mt-2">Cuerpo del documento: </h2>
          <!-- First row Start -->
          <v-row>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense label="Tipo DTE:*" :items="dteOptions" required :rules="required" attach
                v-model="orderHeader.PurchaseOrderType" item-text="text" item-value="value"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense label="Tipo documento:*" :items="documentOptions" required :rules="required" attach
                v-model="orderHeader.PurchaseDocumentType" item-text="text" item-value="value"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="orderHeader.ID_PurchaseLegalInvoiceNumber" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Número documento:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <!--date-picker dense v-model="orderHeader.PurchaseInvoiceDate" class="fast-ease-in-transitioned-element" required
                :rules="required" label="Fecha emisión:* "></date-picker-->
              
              <date-picker label="Fecha" v-model="orderHeader.PurchaseInvoiceDate" @dateSelected="(param) => (orderHeader.PurchaseInvoiceDate = param)" dense
                class="fast-ease-in-transitioned-element">
              </date-picker>
            </v-col>
          </v-row>
          <!-- First row End -->
          <!-- Second row Start -->
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="orderHeader.ItemName" class="fast-ease-in-transitioned-element" required
                :rules="required" label="Descripción:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="orderHeader.PurchaseOrderSubTotal" type="number" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Monto sujeto gravado:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-autocomplete outlined dense label="Código retención MH:*" :items="codIvaRetOptions" required :rules="required" attach
                v-model="orderHeader.codigoRetencionMh" item-text="text" item-value="value"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="orderHeader.PurchaseOrderTotalRet" type="number" class="fast-ease-in-transitioned-element" required
                :rules="required" label="IVA retenido:* "></v-text-field>
            </v-col>
          </v-row>
          <!-- Second row End -->
        </v-col>
        <!-- Cuerpo del documento Column End-->

        <!-- Resumen Column Start-->
        <v-col cols="12">
          <h2 class="pb-6">Resumen: </h2>
          <!-- First row Start -->
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="orderHeader.PurchaseOrderSubTotal" type="number" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Total sujeto retención:* " readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="orderHeader.PurchaseOrderTotalRet" type="number" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Total IVA retenido:* " readonly></v-text-field>
            </v-col>
          </v-row>
          <!-- First row End -->
        </v-col>
        <!-- Resumen Column End-->

        <!-- Extensión Column Start-->
        <!--v-col cols="12">
          <h2 class="pb-6">Extensión: </h2>
          <v-row>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.nombreEntrega" class="fast-ease-in-transitioned-element" required
                :rules="required" label="Nombre entrega:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.documentoEntrega" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Documento entrega:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.nombreRecibe" class="fast-ease-in-transitioned-element" required
                :rules="required" label="Nombre recibe:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="3">
              <v-text-field outlined dense v-model="form.documentoRecibe" class="fast-ease-in-transitioned-element"
                required :rules="required" label="Documento recibe:* "></v-text-field>
            </v-col>
          </v-row>
        </v-col-->
        <!-- Extensión Column End-->

        <!-- Apéndice Column Start-->
        <!--v-col cols="12">
          <h2 class="pb-6">Apéndice: </h2>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.apeniceData1" class="fast-ease-in-transitioned-element" required
                :rules="required" label="apeniceData1:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.apeniceData2" class="fast-ease-in-transitioned-element" required
                :rules="required" label="apeniceData2:* "></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field outlined dense v-model="form.apeniceData3" class="fast-ease-in-transitioned-element" required
                :rules="required" label="apeniceData3:* "></v-text-field>
            </v-col>
          </v-row>
        </v-col-->
        <!-- Extensión Column End-->

        <!-- Buttons Column Start-->
        <v-row class="my-4">
            <v-col cols="12" md="4" class="d-flex justify-start pb-2">
              <v-btn class="btn-clean mx-2" @click="cleanData">
                <v-icon class="mr-2">mdi-broom</v-icon>
                Limpiar
              </v-btn>
            </v-col>
            <v-col cols="12" md="8" class="d-flex justify-end flex-wrap">
              <v-btn class="btn-delete mx-2 mb-3" @click="cancelSalesReceipt">
                <v-icon class="mr-2 mb-2">mdi-close</v-icon>
                Cancelar
              </v-btn>
              <v-btn class="btn-save" @click="saveCr">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Generar comprobante de retención
              </v-btn>
            </v-col>
        </v-row>
        <!-- Buttons Column End-->

      </v-row>
    </v-form>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </div>
</template>
  
<script>
import DatePicker from "@/components/DatePicker"
import Block from "@/components/Block"
import Alerts from "@/components/Alerts"
import { quillConfig } from "@/helpers/quillconfig"
import { mapState, mapGetters } from "vuex"
//import { dui as duiMask, nit as nitMask, nrc as nrcMask } from "@/helpers/masks"
// import { dui as duiMask, nit as nitMask, nrc as nrcMask, applyDUIMaskOutOfInput, applyNITMaskOutOfInput } from "@/helpers/masks"

export default {
  name: "CreateSalesReceipt",
  components: { DatePicker, Block, Alerts },
  props: ["win"],
  data() {
    return {
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      showNotes: false,
      valid: false,
      quillOptions: quillConfig,
      date: null,
      orderHeader: {
        PurchaseOrderType: "",
        ID_PurchaseLegalInvoiceNumber: "",
        PurchaseInvoiceDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10),
        PurchaseOrderSubTotal: "",
        PurchaseOrderTotalRet: "",
        PurchaseDocumentType: "",
        ItemName: "",
        codigoRetencionMh: ""
      },
      orderDetail: {
      },
      required: [(v) => !!v || "El campo es requerido"],
      emailRules: [
        (v) => !!v || "El correo es requerido",
        (v) => /.+@.+/.test(v) || "El correo debe de ser valido",
      ],
      loading: false,
      business: null,
      branch: null,
      user: null,
      showLegaInfo: false,
      dataresponse: null,
      reactive: {
        isNit: false,
        showDocument: true,
        documentLabel: 'Seleccione un tipo de documento'
      },
      dteOptions: [
        { value: "FCF", text: "CONSUMIDOR FINAL" },
        { value: "CCF", text: "CRÉDITO FISCAL" }
      ],
      documentOptions: [
        { value: "1", text: "Físico"},
        { value: "2", text: "Eletronico"}
      ],
      codIvaRetOptions: [
        { value: "22", text: "Retención IVA 1%"},
        { value: "C4", text: "Retención IVA 13%"},
        { value: "C9", text: "Otras retenciones IVA casos especiales"}
      ],
      providers: []
    }
  },
  filters: {
    uppercase(value) {
      return value.toUpperCase()
    }
  },
  watch: {
    fullName: function (value) {
      this.form.legalname = value.toUpperCase()
    },
    date: {
      handler: function () {
        this.form.birthday = this.date
        this.calculateAgeWithDOB()
      },
      immediate: true,
    },
    'orderHeader.codigoRetencionMh'() {
      if(this.orderHeader.codigoRetencionMh == '22'){
        this.orderHeader.PurchaseOrderTotalRet = parseFloat(parseFloat(this.orderHeader.PurchaseOrderSubTotal * 0.01).toFixed(2))
      }
      
      if(this.orderHeader.codigoRetencionMh == 'C4'){
        this.orderHeader.PurchaseOrderTotalRet = parseFloat(parseFloat(this.orderHeader.PurchaseOrderSubTotal * 0.13).toFixed(2))
        //this.orderHeader.PurchaseOrderTotalRet = parseFloat(parseFloat(this.orderHeader.PurchaseOrderSubTotal -  (this.orderHeader.PurchaseOrderSubTotal / 1.13) ).toFixed(2))
      }

      if(this.orderHeader.codigoRetencionMh == 'C9'){
        this.orderHeader.PurchaseOrderTotalRet = 0.0000
      }

    }


  },
  async mounted() {
    this.$store.dispatch('rolesUser/getAllRoles')
    this.roleuser = JSON.parse(localStorage.getItem("user")).roles[0]
    this.business = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.branch = JSON.parse(localStorage.getItem("user")).branch || ""
    this.user = JSON.parse(localStorage.getItem("user")).id || ""
    this.businesstype = JSON.parse(localStorage.getItem("user")).businesstype || ""
    await this.getProviders()
  },
  computed: {
    ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      }),
    ...mapGetters(['getCatalogMH', 'getCitiesCountries']),
    checkCountry() {
      if (this.form.country == 'El Salvador') return true
      else return false
    },
    CurrentNameActivity() {
      const name = JSON.parse(localStorage.getItem("branch")).Country

      if (name == "El Salvador") {
        return "Actividad Economica *"
      } else {
        return "Sector Legal *"
      }
    },
    departments() {
      return this.getCatalogMH('Departamento')
    },
    municipalities() {
      const municipalities = this.getCatalogMH('Municipio')
      if (this.form.department) {
        const department = municipalities.filter(mun => mun.Departamento == this.form.department)
        return department
      }
      return []
    },
    activities() {
      return this.getCatalogMH('ActividadEconomica')
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    stateList() {
      if (this.form.country == 'El Salvador') return []

      const states = this.getCitiesCountries('states')
      const countries = this.getCitiesCountries('countries')
      
      if (this.form.country && Array.isArray(countries)) {
        let currentcountry = countries.find(country => country.name == this.form.country)
        if (currentcountry)
          return states.filter(state => state.country_id == currentcountry.id)
      }

      return []
    },
    cityList() {
      if (this.form.country == 'El Salvador') return []

      if (this.form.department) {
        const cities = this.getCitiesCountries('cities')
        const states = this.getCitiesCountries('states')
        let currentstate = states.find(state => state.name == this.form.department)
        console.log('current state', currentstate)
        if (currentstate) {
          return cities.filter(city => city.state_id == currentstate.id)
        }
        else {
          return []
        }

      }
      else return []

    },

  },
  methods: {
    closeAlert() {
      this.loading = false
      this.alert.show = false;
      this.$store.dispatch('reloadData', 'purchase'); 
      this.cancelSalesReceipt()
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "showErrorDetails") {
        this.showDetailsMessages = true;
      }
    },
    async getProviders() {
      try {
        this.providers = await this.$API.providers.getProviders()
      } catch (error) {
        this.showAlert('error', 'Error!', `${error.message}`)
      }
    },
    /*async maskNit() {
      this.form.legalnit = await nitMask(this, this.form.legalnit)
    },
    async maskNitEvent(e, targetFather, targetChild) {
      e = await nitMask(this, e)
      this[targetFather][targetChild] = e
    },*/
    /*async maskNRC() {
      this.form.legalnrc.length > 1 && (this.form.legalnrc = await nrcMask(this, this.form.legalnrc))
    },*/
    /*varifycontent(targetFather, targetChild) {
      if (this[targetFather].documenttype == 1) {

        duiMask(this, this[targetFather][targetChild]).then(result => {
          this[targetFather][targetChild] = result
        })
      }
    },*/
    /*changueValueactivity() {
      this.form.descactividad = this.activities.find(act => act.Codigo == this.form.codactividad).Valores
    },*/
    /*uppercaseInput(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase()
    },*/
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = options != null ? options : null
    },
    /*addressDetails(event, title) {
      this.form[title] = event
      if (title == 'address') {
        this.form['legaladdress'] = event
      }
    },*/
    changeProviderData() {
      /*const selectedProvider = this.providers.find((provider) => provider.ID_Provider == this.form.providerId)

      this.form.legalnit = selectedProvider.ProviderLegalNIT
      this.form.nrc = selectedProvider.ProviderLegalNRC
      this.form.phonenumber = selectedProvider.ProviderContactPhone
      this.form.mobilenumber = selectedProvider.ProviderContactName
      this.form.country = selectedProvider.ProviderCountry
      this.form.department = selectedProvider.ProviderState
      this.form.municipality = selectedProvider.ProviderCity
      this.form.email = selectedProvider.ProviderContactEmail
      this.form.address = selectedProvider.ProviderAddress
      this.form.codactividad = selectedProvider.ProviderLegalSector*/
    },
    cleanData() {
      this.orderHeader.PurchaseOrderType = ""
      this.orderHeader.ID_PurchaseLegalInvoiceNumber = ""
      this.orderHeader.PurchaseInvoiceDate = this.moment().format("DD/MM/YYYY")
      this.orderHeader.PurchaseOrderSubTotal = ""
      this.orderHeader.PurchaseOrderTotalRet = ""
      this.orderHeader.ItemName = ""
      this.orderHeader.codigoRetencionMh = ""
      this.orderHeader.PurchaseDocumentType = ""

      this.orderDetail = {
        
      }
      /*this.form = {
        firstname: "",
        lastname: "",
        legalname: "",
        providerId: "",
        legalnit: "",
        nrc: "",
        codactividad: "",
        phonenumber: "",
        mobilenumber: "",
        country: "",
        department: "",
        municipality: "",
        email: "",
        address: "",
        tipoDte: "",
        tipoDocumento: "",
        numeroDocumento: "",
        fechaEmisio: "",
        Descripcion: "",
        montoSujetoGravado: "",
        ivaRetenido: "",
        codigoRetencionMh: "",
        totalSujetoRetencion: "",
        totalIvaRetenido: "",
        totalIvaRetenidoLetras: "",
        nombreEntrega: "",
        documentoEntrega: "",
        nombreRecibe: "",
        documentoRecibe: "",
        tipoDteExtension: "",
        tipoDocumentoExtension: "",
        fechaEmisionExtension: "",
      }*/
    },
    closeThisModal() {
      this.$emit('close-modal')
    },
    cancelSalesReceipt() {
      // this.showAlert('danger', 'Error', "No se guardaron cambios.")
      this.cleanData()
      this.closeThisModal()
    },
    saveCr() {
      this.loading = true
      this.win.codpointsale = localStorage.getItem("codPointSale")
      this.$API.orders.generateWithholdingReceipt2({ orderHeader: this.orderHeader, orderDetail: [this.orderDetail], provider: this.win, user: JSON.parse(localStorage.getItem("user")).id})
                .then(response => {
                    if (response.estado == "PROCESADO") {
                        let mensaje = ""
                        mensaje = "Se ha realizdo y procesado la factura.<br/><br/>"
                        
                        mensaje += "Estado: " + response.estado + "<br/>";
                        mensaje += "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
                        mensaje += "Observaciones DTE: <br/>"
                        if (response.observaciones.length == 0) {
                            mensaje += "- Ninguna observación <br/>"
                        }
                        else {
                            for (let i = 0; i < response.observaciones.length; i++) {
                            mensaje += "- " + response.observaciones[i] + "<br/>"
                            }
                        }
                        this.$emit('reloadData')
                        //this.datatoprint.header = await this.$API.invoices.getInvoice(response.billingDteJsonHeader.ID_Invoice);
                        //this.datatoprint.body = await this.$API.invoices.getAllInvoiceItems(response.billingDteJsonHeader.ID_Invoice);
                        this.showAlert(
                            "successHtml",
                            "Factura generada",
                            mensaje
                        );

                    }
                    else {
                        let mensaje = "Se ha rechazado la factura, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.<br/><br/>";
                        mensaje += "Estado: " + response.estado + "<br/>";
                        mensaje += "Mensaje del DTE: <br/> - " + response.descripcionMsg + "<br/>";
                        mensaje += "Observaciones DTE: <br/>"
                        if (response.observaciones.length == 0) {
                            mensaje += "- Ninguna observación <br/>"
                        }
                        else {
                            for (let i = 0; i < response.observaciones.length; i++) {
                            mensaje += "- " + response.observaciones[i] + "<br/>"
                            }
                        }


                        this.showAlert(
                            "successHtml",
                            "Factura generada",
                            mensaje
                        );
                    }
                })
                .catch(() => {
                    this.loading = false
                    let message = "Ocurrio un error crítico al realizar la factura. ¡Por favor contáctarse con el administrador del sistema!"
                    // message += "<br/><br/>"+JSON.stringify(err)
                    //this.currenterrordata = JSON.stringify(err)
                    this.showAlert(
                        "danger",
                        "Advertencia",
                        message,
                    )
                })

      // this.showAlert("success", "Éxito", "El comprobante de retención se agregó de forma correcta.")
      //this.cleanData()
      //this.closeThisModal()
    }
  },
}
</script>
  
<style scoped>
.btn-add {
  background-color: #3498DB !important;
  color: white;
}

.btn-save {
  background-color: #00A178 !important;
  color: white;
}

.btn-clean {
  background-color: #F29D35 !important;
  color: white;
}

.btn-disable {
  background-color: #41464c !important;
  color: white;
}


.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {

  cursor: pointer;
}

.btn-delete {
  background-color: #E3191E !important;
  color: white;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.tabsNH {
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #F29D35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 10px;

}

.box {
  border-style: solid;
  border-color: black;
  border-width: 1px;

  border-radius: 15px;


}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #FFB266;
  cursor: pointer;
}
</style>
  