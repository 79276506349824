<template>
    <window-portal :open="data.show" @close="data.show = false">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center>
                <div>
                    <p style="font-size: 30px;">Vista previa de impresión de Documento de Retención</p>
                </div>
            </center>
            <br />
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="downLoadJSON()">
                    Descargar JSON
                </button>
            </center>
            <!-- {{ JSON.stringify(data) }} -->
            <center>
                <div v-if="loading">
                    <p>Cargando...</p>
                </div>
            </center>
        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>

        <div class="book"
            style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">

            <!--Configuración de la pagina-->
            <div class="page" ref="dataprint"
                style="position: relative; width: 1000px; min-height: 1294px; background: white; margin: 0 auto; margin-bottom: 20px;">

                <!-- Encabezado-->

                <!-- Configuración del contenido de la pagina -->
                <div class="subpage" style="height: 950px; padding-top: 20px; overflow: hidden;">
                    <div style="margin-left: 80px; margin-right: 50px;">
                        <div style="width: 100%;">
                            <table style="font-size: 12px; border-collapse: collapse; width: 100% !important;">
                                <tr style="">
                                    <td colspan="5" style="text-align: center;"><h2>DOCUMENTO TRIBUTARIO ELECTRÓNICO</h2></td>
                                </tr>
                                <tr style="">
                                    <td colspan="5" style="text-align: center;"><h1>COMPROBANTE DE RETENCIÓN</h1></td>
                                </tr>
                                <tr>
                                    <td colspan="5">
                                        <hr />
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Código Generación:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ data.data.IdentificacionCodigoGeneracion }} 
                                    </td>

                                    <td rowspan="3" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <img ref="qrcode"/>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Modelo de facturación:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ data.data.IdentificacionNumeroControl }}
                                    </td>

                                </tr>
                                <tr>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Número de Control:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ data.data.IdentificacionNumeroControl }}
                                    </td>

                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Tipo de Transmisión:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ data.data.IdentificacionTipoOperacion ? 'NORMAL' : 'CONTINGENCIA' }}
                                    </td>
                                </tr>
                                <tr>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Sello de recepción:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ data.data.SelloRecibido }}
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        <b>Fecha y hora de generación:</b>
                                    </td>
                                    <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%;">
                                        {{ `${data.data.IdentificacionFecEmi} ${data.data.IdentificacionHorEmi}` }}
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <br />
                        <div style="width: 100%;">
                            <table
                                style="width: 100% !important; font-size: 11px; margin-top: 40px;">
                                <tr>
                                    <td colspan="6" style="text-align: center;"><h2>AGENTE DE RETENCIÓN</h2></td>
                                </tr>

                                <div style="border-collapse: collapse; border: 1px solid black;">
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Nombre o Razón Social:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667;">
                                            {{ data.data.EmisorNombre }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Nombre comercial:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.BusinessName }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>NIT:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ applyNitMaskHandlingHomologationOutOfInput(data.data.EmisorNit) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>NRC:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667;">
                                            {{ applyDUIMaskOutOfInput(data.data.EmisorNrc) }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Actividad Económica:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.EmisorDescActividad }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Dirección:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.EmisorDireccionComplemento }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Número de Teléfono:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667;">
                                            {{ data.data.EmisorTelefono }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Correo Electrónico:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.EmisorDescActividad }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Tipo de Establecimiento:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.EmisorTipoEstablecimiento === "01" ? "Sucursal Agencia" : "" }}
                                            {{ data.data.EmisorTipoEstablecimiento === "02" ? "Casa Matriz" : "" }}
                                            {{ data.data.EmisorTipoEstablecimiento === "04" ? "Bodega" : "" }}
                                            {{ data.data.EmisorTipoEstablecimiento === "07" ? "Predio y/o patio" : "" }}
                                            {{ data.data.EmisorTipoEstablecimiento === "20" ? "Otro" : "" }}
                                        </td>
                                    </tr>
                                </div>
                            </table>
                            <table
                                style="width: 100% !important; font-size: 11px; margin-top: 40px;">
                                <tr>
                                    <td colspan="6" style="text-align: center;"><h2>SUJETO DE RETENCIÓN (RECEPTOR)</h2></td>
                                </tr>

                                <div style="border-collapse: collapse; border: 1px solid black;">
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Nombre o Razón Social:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667;">
                                            {{ data.data.ReceptorNombre }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Tipo de documento de identificación:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorTipoDocumento === '36' ? "NIT" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '13' ? "DUI" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '37' ? "OTRO" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '03' ? "PASAPORTE" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '02' ? "CARNET DE RESIDENTE" : "" }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>NRC:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667;">
                                            {{ applyDUIMaskOutOfInput(data.data.ReceptorNrc) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Actividad Económica:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorDescActividad }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>N° de Documento de Identificación:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorTipoDocumento === '36' ? applyNitMaskHandlingHomologationOutOfInput(data.data.ReceptorNumDocumento) : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '13' ? applyDUIMaskOutOfInput(data.data.ReceptorNumDocumento) : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '37' ? "OTRO" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '03' ? "PASAPORTE" : "" }}
                                            {{ data.data.ReceptorTipoDocumento === '02' ? "CARNET DE RESIDENTE" : "" }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Nombre Comercial:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorNombreComercial }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Dirección:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorDireccionComplemento }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Correo Electrónico:</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            {{ data.data.ReceptorCorreo }}
                                        </td>
                                    </tr>
                                </div>
                            </table>
                            <table
                                style="width: 100% !important; font-size: 11px; margin-top: 40px; border-collapse: collapse; ">
                                <div>
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            <b>N°</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            <b>Tipo de Doc. Relacionado</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            <b>N° de Doc. Relacionado</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            <b>Fecha del doc.</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            <b>Descripción</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            <b>Monto Sujeto a Retención</b>
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            <b>IVA Retenido</b>
                                        </td>
                                    </tr>
                                    <tr v-for="(item, index) in data.body" :key="index">
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            {{ item.CuerpoDocumentoNumItem }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            {{ item.CuerpoDocumentoTipoDoc}}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            {{ item.CuerpoDocumentoNumDocumento }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            {{ item.CuerpoDocumentoFechaEmision }}
                                        </td>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 20%; border-collapse: collapse; border: 1px solid black;">
                                            {{item.CuerpoDocumentoDescripcion}}
                                        </td>
                                        <td style="text-align: right; padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            ${{ item.CuerpoDocumentoMontoSujetoGrav.toFixed(2) }}
                                        </td>
                                        <td style="text-align: right; padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 10%; border-collapse: collapse; border: 1px solid black;">
                                            ${{ item.CuerpoDocumentoIvaRetenido.toFixed(2) }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">
                                            
                                        </td>
                                        <td colspan="3" style="text-align: right; padding-left: 10px; padding-right: 10px; padding-bottom: 5px; border-collapse: collapse; border: 1px solid black;">
                                            <b>Total Monto Sujeto a Retención</b>
                                        </td>
                                        <td style=" text-align: right;padding-left: 10px; padding-right: 10px; padding-bottom: 5px; border-collapse: collapse; border: 1px solid black;">
                                            ${{data.body.reduce((acum, current) => acum += current.CuerpoDocumentoMontoSujetoGrav, 0).toFixed(2)}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="3" style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px;">
                                            
                                        </td>
                                        <td colspan="3" style="text-align: right; padding-left: 10px; padding-right: 10px; padding-bottom: 5px; border-collapse: collapse; border: 1px solid black;">
                                            <b>Total IVA retenido</b>
                                        </td>
                                        <td style=" text-align: right;padding-left: 10px; padding-right: 10px; padding-bottom: 5px; border-collapse: collapse; border: 1px solid black;">
                                            ${{data.body.reduce((acum, current) => acum += current.CuerpoDocumentoIvaRetenido, 0).toFixed(2)}}
                                        </td>
                                    </tr>
                                    
                                </div>
                            </table>
                            <table
                                style="width: 100% !important; font-size: 11px; margin-top: 40px;">
                                <div style="border-collapse: collapse; border: 1px solid black;">
                                    <tr>
                                        <td style="padding-left: 10px; padding-right: 10px; padding-bottom: 5px; width: 16.66666666666667%;">
                                            <b>Valor en letras IVA Retenido: </b>{{ GetnumberToText(data.body.reduce((acum, current) => acum += current.CuerpoDocumentoIvaRetenido, 0)) }}
                                        </td>
                                    </tr>
                                </div>
                            </table>
                        </div>
                    </div>
                </div>

                <div v-html="footer" style="width: 100% !important;"></div>
            </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import {numberToText} from '@/helpers/conveterNumberText.js'
import {
    applyDUIMaskOutOfInput,
    applyNitMaskHandlingHomologationOutOfInput
} from "../../helpers/masks"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
    name: "PrintReceipWithHolding",
    props: ['data'],
    components: { WindowPortal },
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
        }
    },
    watch: {
        'data.show'(){
            this.$nextTick(() => {

                if(this.data.show){
                    const { IdentificacionAmbiente, IdentificacionCodigoGeneracion, IdentificacionFecEmi } = this.data.data;
    
                    const url = `https://admin.factura.gob.sv/consultaPublica?ambiente=${IdentificacionAmbiente}&codGen=${IdentificacionCodigoGeneracion}&fechaEmi=${IdentificacionFecEmi}`;
    
                    const opt = {
                        errorCorrectionLevel: 'H',
                        type: 'image/png',
                        quality: 0.3,
                        margin: 1,
                        color: {
                            dark:"#000000",
                            light:"#ffffff"
                        },
                        width: 100
                    }
    
                    QRCode.toDataURL(url, opt, ( error, url ) => {
                        if(error){
                            console.log('Error al generar código qr: ' + error.message);
                            throw error;
                        }
    
                        console.log();
                        this.$refs.qrcode.src = url;
                    })
                }
            })
        }
    },
    mounted() {
        console.log(this.data);
        this.getHeaderFooter()
    },
    methods: {
        GetnumberToText(number) {
            if(number) return numberToText(number)
            else return ''
        },
        applyDUIMaskOutOfInput(value){
            return applyDUIMaskOutOfInput(value);
        },
        applyNitMaskHandlingHomologationOutOfInput(value){
            return applyNitMaskHandlingHomologationOutOfInput(value);
        },
        getHeaderFooter() {
            let search = {};
            search.ID_business = JSON.parse(localStorage.getItem("user")).businessid || ""
            search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || ""
            this.$API.HF.getHF(search).then((data) => {
                let header = [], footer = [];
                header = data.filter((x) => x.State == '1' && x.Type == 'Header');
                this.header = header.length > 0 ? JSON.parse(header[0].Body).html : '';
                this.header = this.header.replace(/(\n)/gm, "");
                footer = data.filter((x) => x.State == '1' && x.Type == 'Footer');
                this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : '';
            })
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },
        assignCuerpoDocumento() {
            this.data.data.jsonMH.cuerpoDocumento = this.data.body.map( item => ( {
                "descripcion": item.CuerpoDocumentoDescripcion,
                "numDocumento": item.CuerpoDocumentoNumDocumento,
                "tipoDoc": item.CuerpoDocumentoTipoDoc,
                "codigoRetencionMH": item.CuerpoDocumentoCodigoRetencionMH,
                "fechaEmision": item.CuerpoDocumentoFechaEmision,
                "montoSujetoGrav": item.CuerpoDocumentoMontoSujetoGrav,
                "tipoDte": item.CuerpoDocumentoTipoDte,
                "numItem": item.CuerpoDocumentoNumItem,
                "ivaRetenido": item.CuerpoDocumentoIvaRetenido
            } ) );
        },
        async downLoadJSON() {
            try {
                // console.log( this.data.body );
                // console.log( this.data.data.ID_CR+ "_"+this.data.data.IdentificacionFecEmi.replaceAll("/","-") + "_"+this.data.data.ReceptorNombreComercial.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json" );
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.data.data.ID_CR+ "_"+this.data.data.IdentificacionFecEmi.replaceAll("/","-") + "_"+this.data.data.ReceptorNombreComercial.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json");
                this.assignCuerpoDocumento();
                const blob = new Blob([JSON.stringify(this.data.data.jsonMH)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.click();
                URL.revokeObjectURL(href);
                // aElement.download = this.dataProps.data.header.ID_Invoice+ "_"+this.dataProps.data.header.InvoiceDate.replaceAll("/","-") + "_"+this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"','')+ ".json";
                // this.assignCuerpoDocumento();
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al descargar JSON';
                console.log(error);
            }
        },
        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save(this.data.data.ID_CR+ "_"+this.data.data.IdentificacionFecEmi.replaceAll("/","-") + ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
    }
}
</script>

<style scoped>
.u-row .u-col-100 {
    width: 100% !important;
}

.btnclean {
    border-radius: 15px;
    border: 1px solid white;
    background-color: #F29D35 !important;
    color: white !important;
}

.btnclose {
    border-radius: 15px;
    border: 1px solid white;
    background-color: rgb(255, 38, 38) !important;
    color: white !important;

}

* {
    font-family: Arial, Helvetica, sans-serif !important;
}</style>
